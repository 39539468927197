/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children, lang }) => {
  return (
    <>
      <link rel="stylesheet" href="/css/foundation.css"/>
      <link rel="stylesheet" href="/css/app.css"/>
      <link rel="stylesheet" href="//use.typekit.net/foa4qmx.css"/>
      <Header lang={lang}/>
      <main>{children}</main>
      <footer className="footer">
        <div className="grid-container">
          <div className="grid-x grid-margin-x small-up-1 medium-up-4">
            <figure className="cell grid-x fw-logo">
              <img className="small-6 medium-6 cell" src="/img/svg/logo-blue.svg"/>
            </figure>
            <div className="cell fw-each-credit grid-x">
              <h5 className="small-12 cell">Headquarters</h5>
              <ul className="small-12 cell">
                <li>Loc. Palazzo del Pero, 23</li>
                <li>52100 Arezzo (AR) - Italia</li>
                <li>Tel. +39 0575 984164</li>
                <li>Fax +39 0575 984238</li>
                <li><a href="mailto:biosys@biosys.it">biosys@biosys.it</a></li>
              </ul>
            </div>
            <div className="cell fw-each-credit grid-x">
              <h5 className="small-12 cell">Instrument Division</h5>
              <ul className="small-12 cell">
                <li>Instrument Division</li>
                <li>Via B. Buozzi, 253</li>
                <li>50013 Campi Bisenzio (FI) - Italia</li>
                <li>Tel. +39 055 8963140</li>
                <li> Fax +39 055 8997086</li>
                <li><a href="mailto:biosys@biosys.it">biosys@biosys.it</a></li>
              </ul>
            </div>
            <div className="cell fw-each-credit grid-x">
              <h5 className="small-12 cell">Privacy</h5>
              <ul className="small-12 cell">
                <li><a href="http://biosys.it/pdf/privacy/BSI_Informativa_Clienti.pdf" target="_blank">Informativa
                  clienti</a></li>
                <li><a href="http://biosys.it/pdf/privacy/BSI_Informativa_Fornitori.pdf" target="_blank">Informativa
                  fornitori</a></li>
                <li><a href="http://biosys.it/pdf/privacy/Informativa_web_cookies.pdf" target="_blank">Informativa web
                  cookies</a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
