import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Layout from "./layout"

const Header = ({ siteTitle, lang }) => (
  <section className="header fw-mod-bg" id={"start-kit-section"}>
    <header className="grid-container">
      <div className=" grid-x fw-head">
        <div className="fw-logo float-left">
          <figure className="small-12 cell grid-x">
            <img src="/img/svg/logo-white.svg"/>
          </figure>
        </div>
        <div className="fw-menu float-right">
          <ul className="fw-anchor-links hide-for-small-only">
            <li><a href="#start-kit-section">{lang.menu.startKit}</a></li>
            <li><a href="#video-section">{lang.menu.video}</a></li>
            <li><a href="#download-section">{lang.menu.download}</a></li>
          </ul>
          <ul className="fw-lang-links">
            <li><a href={lang.menu.it}>{lang.menu.it}</a></li>
            <li><a href={lang.menu.en}>{lang.menu.en}</a></li>
            {/*<li><a href={lang.menu.es}>{lang.menu.es}</a></li>*/}
          </ul>
        </div>
      </div>
    </header>

    <article className="grid-container fw-main-article">
      <div className="grid-x fw-main-content">
        <div className="small-12 medium-6 large-4 cell grid-x">
          <figure className="small-12 cell grid-x text-center">
            <img src="/img/svg/multicarein-logo.svg"/>
          </figure>
          <div className="fw-article small-12 cell">
            {lang.header.p1}<br/><br/>
            {lang.header.p2}
          </div>
          <a href="#download-section" className="small-12 cell fw-link">{lang.header.btnDownload}</a>
        </div>
      </div>

    </article>

  </section>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
